import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutRoutingModule } from './layout-routing.module';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SideBarComponent } from './side-bar/side-bar.component';


@NgModule({
  declarations: [AdminLayoutComponent, NavBarComponent, SideBarComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    SharedModule,
    LayoutRoutingModule
  ],
  exports: [AdminLayoutComponent]
})
export class LayoutModule { }
