import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMessage } from 'src/app/objects/responseMessage.object';
import { AcademicYear } from 'src/app/objects/year.object';
import { AcademicYearService } from 'src/app/services/academic-year.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  closeResult: string;
  activeYear = new AcademicYear();
  @Output() activeYearLayout = new EventEmitter();
  yearList: AcademicYear[];
  newAcademicYear: string;
  changedAcademicYear = new AcademicYear();
  successMessage = '';
  errorMessage = '';
  onAddNewYear = false;
  responseMessage = new ResponseMessage();

  constructor(private authService: AuthService, private router: Router, private modalService: NgbModal,
    private yearService: AcademicYearService) { }

  ngOnInit() {
    this.getActiveYear();
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  logOut() {
    this.authService.logOut();
    this.router.navigate(['auth/login']);
  }
  getActiveYear() {
    this.yearService.getActiveYear();
    this.yearService.activeYearEmitter.subscribe(
      data => {
        this.activeYear = data;
        this.activeYearLayout.emit(data);
      }
    );
  }
  getAllYears() {
    this.yearService.getAllAcademicYear();
    this.yearService.academicYearsEmitter.subscribe(
      data => {
        this.yearList = data;
      }
    );
  }
  changeActiveYear() {
    this.yearService.setActiveYear(this.changedAcademicYear.id);
    this.yearService.responseEmitter.subscribe(response => {
      this.activeYear = this.changedAcademicYear;
      this.changedAcademicYear = new AcademicYear();
      this.responseMessage.message = response.message || "Academic year set successfully";
      this.responseMessage.type = 'success';
    });
    this.yearService.errorEmitter.subscribe(response => {
      this.responseMessage.message = response.message;
      this.responseMessage.type = 'danger';
    });

  }
  addNewYear() {
    this.yearService.addAcademicYear(this.newAcademicYear);
    this.yearService.responseEmitter.subscribe(response => {
      this.newAcademicYear = '';
      this.responseMessage.message = response.message || "Academic year added successfully";
      this.responseMessage.type = 'success';
    });
    this.yearService.errorEmitter.subscribe(response => {
      console.log(response);
      this.responseMessage.message = response[0].message;
      this.responseMessage.type = 'danger';
    });
  }
  isCashier() {
    return this.authService.isCashier();
  }
  isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }
  isStudentRegistrar() {
    return this.authService.isStudentRegistrar();
  }
  isHomeRoom(){
    return this.authService.isHomeRoom();
  }

}
