import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-header',
  templateUrl: './print-header.component.html',
  styleUrls: ['./print-header.component.css']
})
export class PrintHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
