export class AcademicYear {
    public id: string;
    public uuid: string;
    public year: string;
    public active: number;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.year = null;
        this.active = 0;
    }
}
