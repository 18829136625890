import { Injectable, EventEmitter } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { LoginUser } from '../auth/auth.object';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    token = this.getUserToken();
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    AuthenticatedUserEmitter = new EventEmitter<boolean>();
    AuthErrorEmitter = new EventEmitter<string>();

    constructor(private httprequest: HttpRequestService) {
    }

    authenticate(loginUser: LoginUser) {
        return this.httprequest.sendPostRequest('auth/login', loginUser, this.token)
            .subscribe(
                data => {
                    console.log(data);
                    this.processAuthenticateUser(data);
                },
                err => {
                    console.log(err);
                    this.AuthErrorEmitter.emit(err);
                }
            );
    }
    processAuthenticateUser(data: any) {
        if (data.user) {
            this.setUserToken(data.user.token);
            this.setUserId(data.user.id);
            // this.setFirstName(data.data.user.first_name);
            // this.setFatherName(data.data.user.father_name);
            this.setUserName(data.user.email);
            // this.setRoleName(data.data.roles[0]);
            // this.setHomeRoom(data.data.home_room_teacher);
            this.AuthenticatedUserEmitter.emit(data.user);
        }
    }

    logOut() {
        // this.setRoleName('');
        // this.setFirstName('');
        // this.setFatherName('');
        this.setUserName('');
        this.setUserToken('');
        this.setUserId('');
        // this.setRoleName('');
        // this.setImgUrl('');
    }

    setUserToken(user_token: string) {
        localStorage.setItem('token_auth_key', user_token);
    }
    setHomeRoom(home_room_teacher) {
        if(home_room_teacher){
            localStorage.setItem('is_home_room', 'true');
        }
    }

    setUserName(username: string) {
        localStorage.setItem('username', username);
    }

    setFirstName(first_name: string) {
        localStorage.setItem('first_name', first_name);
    }

    setFatherName(father_name: string) {
        localStorage.setItem('father_name', father_name);
    }

    setUserId(id: string) {
        localStorage.setItem('id', id);
    }

    getUserToken() {
        return localStorage.getItem('token_auth_key');
    }
    getHomeRoom() {
        return localStorage.getItem('is_home_room');
    }

    getUserId() {
        return localStorage.getItem('id');
    }

    getUsername() {
        return localStorage.getItem('username');
    }

    getFirstName() {
        return localStorage.getItem('first_name');
    }

    getFatherName() {
        return localStorage.getItem('father_name');
    }

    setRoleName(user_name: string) {
        return localStorage.setItem('user_role_name', user_name);
    }

    getRoleName() {
        return localStorage.getItem('role');
    }
    isSuperAdmin() {
        return true;
        // return (this.getRoleName() === 'admin');
    }
    isCashier() {
        return (this.getRoleName() === 'cashier');
    }
    isStudentRegistrar() {
        return (this.getRoleName() === 'student-registrar');
    }
    isHomeRoom() {
        return (this.getHomeRoom() === 'true');
    }

    //  setImgUrl(img_url: string) {
    //     return localStorage.setItem('img_url', img_url);
    // }

    //  getImgUrl() {
    //     return localStorage.getItem('img_url');
    // }


}
