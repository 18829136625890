import { Injectable, EventEmitter } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { AuthService } from './auth.service';
import { HttpHeaders } from '@angular/common/http';
import { AcademicYear } from '../objects/year.object';

@Injectable({
  providedIn: 'root'
})
export class AcademicYearService {
  token = this.authService.getUserToken();
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  activeYearEmitter = new EventEmitter<AcademicYear>();
  academicYearsEmitter = new EventEmitter<AcademicYear>();
  responseEmitter = new EventEmitter();
  errorEmitter = new EventEmitter();

  constructor(private httprequest: HttpRequestService, private authService: AuthService) { }

  addAcademicYear(year: string) {
    return this.httprequest.sendPostRequest('academic/academic-years', { year }, this.token)
      .subscribe(
        data => this.responseEmitter.emit(data),
        error => {
          this.errorEmitter.emit(error.error.data);
          console.log(error.error);
        }
      );

  }
  getActiveYear() {
    this.httprequest.sendGetRequest('academic/academic-years/active', this.token).subscribe(
      data => {
        this.processGetActiveYear(data);
      },
      error => {
        console.log('Something Went Wrong!!!', error);
      },
    );
  }
  processGetActiveYear(yearData: any) {
    if (yearData) {
      this.setActiveYearId(yearData.id);
      this.activeYearEmitter.emit(yearData);
    }
  }
  setActiveYearId(yearId: string){
    localStorage.setItem('academic_year_id', yearId);
  }
  getActiveYearId(){
    return localStorage.getItem('academic_year_id');
  }
  getAllAcademicYear() {
    this.httprequest.sendGetRequest('academic/academic-years', this.token).subscribe(
      data => {
        this.processGetAllAcademicYear(data);
      },
      error => {
        console.log('Something Went Wrong!!!', error);
      },
    );
  }
  processGetAllAcademicYear(yearData: any) {
    if (yearData.data) {
      this.academicYearsEmitter.emit(yearData.data);
    }
  }
  setActiveYear(uuid: string) {
    return this.httprequest.sendPostRequest('academic/academic-years/set-active/' + uuid, {}, this.token)
      .subscribe(
        data => {
          this.setActiveYearId(uuid);
          this.responseEmitter.emit(data);
        },
        error => {
          this.errorEmitter.emit(error.error.data);
          console.log(error);
        }
      );
  }
}
