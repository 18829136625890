import { Component, Input, OnInit } from '@angular/core';
import { ResponseMessage } from 'src/app/objects/responseMessage.object';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {

  @Input() responseMessage: ResponseMessage;

  constructor() { }

  ngOnInit() {
    // setTimeout(() => {
    //   this.responseMessage.isDisplayed = false;
    // }, 2000);
  }

  onCancel(){
    this.responseMessage.isDisplayed = false;
  }


}
