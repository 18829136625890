import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HttpRequestService {
  // private  rootURl = 'http://localhost:8000/api/';
  //   private  rootURl = 'http://127.0.0.1:7000/api/';
  // private  rootURl = 'http://127.0.0.1:3000/api/';
  // private rootURl = "http://localhost:9997/api/";
  // private  rootURl = 'http://192.168.43.199:8000/api/';
  //   private  rootURl = 'http://192.168.0.106:7000/api/';
  private rootURl = "https://schedule-api.temarima.com/api/";

  constructor(private httpRequest: HttpClient) {}

  public sendGetRequest(routeName, token) {
    const request_header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.httpRequest.get(this.rootURl + routeName, {
      headers: request_header,
    });
  }
  public sendPostRequest(routeName, body, token) {
    const request_header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.httpRequest.post(this.rootURl + routeName, body, {
      headers: request_header,
    });
  }
  public sendSchedulePostRequest(routeName, token) {
    const request_header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.httpRequest.post(this.rootURl + routeName, {
      headers: request_header,
    });
  }
  public sendPutRequest(routeName, body, token) {
    const request_header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.httpRequest.put(this.rootURl + routeName, body, {
      headers: request_header,
    });
  }
  public sendPatchRequest(routeName, body, token) {
    const request_header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.httpRequest.patch(this.rootURl + routeName, body, {
      headers: request_header,
    });
  }
  public sendCustomPatchRequest(routeName, token) {
    const request_header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.httpRequest.patch(this.rootURl + routeName, {
      headers: request_header,
    });
  }
  public sendCustomGetRequest(fullUrl) {
    return this.httpRequest.get(fullUrl);
  }
  public sendCustomSearchRequest(fullUrl, body, token) {
    const request_header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.httpRequest.post(fullUrl, body, { headers: request_header });
  }
  public sendSearchPostRequest(fullUrl, body, token) {
    const request_header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.httpRequest.post(fullUrl, body, { headers: request_header });
  }
  public sendDeleteRequest(routeName, token) {
    const request_header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.httpRequest.delete(this.rootURl + routeName, {
      headers: request_header,
    });
  }
  public sendCustomPostRequest(routeName, body) {
    return this.httpRequest.post(this.rootURl + routeName, body);
  }
}
