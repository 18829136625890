export class ResponseMessage {
    type: string;
    message: string;
    isDisplayed: boolean;

    constructor() {
        this.type = null;
        this.message = null;
        this.isDisplayed = false;
    }
}
