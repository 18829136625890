import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [{
      path: 'auth',
      loadChildren: './auth/auth.module#AuthModule'
    }]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'payment/add',
        loadChildren: './add-payment/add-payment.module#AddPaymentModule'
      },
      {
        path: 'payment/view',
        loadChildren: './view-payment/view-payment.module#ViewPaymentModule'
      },
      {
        path: 'learning-process',
        loadChildren: './learning-process/learning-process.module#LearningProcessModule'
      },
      {
        path: 'student',
        loadChildren: './student/student.module#StudentModule'
      },
      {
        path: 'material',
        loadChildren: './material/material.module#MaterialModule'
      },
      {
        path: 'library',
        loadChildren: './library/library.module#LibraryModule'
      },
      {
        path: 'grade',
        loadChildren: './grade/grade.module#GradeModule'
      },
      {
        path: 'employee',
        loadChildren: './teacher/teacher.module#TeacherModule'
      },
      {
        path: 'attendance',
        loadChildren: './attendance/attendance.module#AttendanceModule'
      },
    ]
  },
  { path: '**', redirectTo: '/auth/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
